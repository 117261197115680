<template>
  <div class="update-reglement-dynamic">
    <b-button
      class="button-update-facture-style m-1"
      size="sm"
      variant="success"
      title="Modifier un Paiement"
      @click.prevent.stop="handleUpdate()"
    >
      <font-awesome-icon icon="pencil-alt" />
    </b-button>
    <b-modal
      id="updateReglementFactureDynamic"
      ref="updateReglementFactureDynamic"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      size="xl"
      @hide="$emit('closeModal')"
      @hidden="hideModal('updateReglementFactureDynamic')"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-reglement-dynamic"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier Paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateReglementFactureDynamic')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitUpdateReglement"
          >
            <div v-if="show == true" class="loading-custom-template-block">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
            <template v-if="reglementToUpdate != null">
              <b-row>
                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Type Partenaire"
                    label-for="type_partenaire"
                    class="mr-2"
                  >
                    <b-form-select
                      id="type_partenaire"
                      v-model="reglementToUpdate.type_partenaire"
                      :options="computedTypePartenaire"
                      text-field="text"
                      value-field="value"
                      required
                      class="b-form-select-raduis"
                      :disabled="true"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col col xs="2.5" sm="2.5" md="2.5">
                  <b-form-group
                    label="Partenaire"
                    label-for="client"
                    class="input-modal-champ mr-2"
                  >
                    <b-form-input
                      id="client"
                      type="text"
                      required
                      v-model="reglementToUpdate.client"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Type paiement"
                    label-for="type"
                    class="mr-2"
                  >
                    <b-form-select
                      v-model="reglementToUpdate.type_id"
                      id="type"
                      :options="computedTypeReglement"
                      required
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <lcr
                  v-if="
                    reglementToUpdate.echeances.length == 0 &&
                      facturesReglement.length > 0
                  "
                  :montantFix="true"
                  :reglement="computedReg"
                  :factures="facturesReglement"
                  @saveDataForLcr="saveDataForLcr"
                  :resetModalLcr="resetModalLcr"
                />
                <b-col
                  col
                  xs="2.5"
                  sm="2.5"
                  md="2.5"
                  v-if="!computedPaiementNameSelected.includes('LCR')"
                >
                  <b-form-group
                    label="Conditions du paiement"
                    label-for="condition"
                    class="mr-2"
                  >
                    <b-form-select
                      v-model="reglementToUpdate.condition_id"
                      required
                      id="condition"
                      :options="computedConditionPaiement"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col
                  col
                  xs="2"
                  sm="2"
                  md="2"
                  v-if="getConditionAmountNonLettre"
                >
                  <b-form-group
                    label="Montant non alloué"
                    label-for="reste"
                    class="input-modal-champ col-2 p-0 mr-2 orange-color"
                  >
                    <div class="text-dark custom-input-dynamic-reglement">
                      {{ getAmountNonLettrerReglemnt | numberWithSpaces }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  col
                  :xs="getConditionAmountNonLettre ? '2' : '4'"
                  :sm="getConditionAmountNonLettre ? '2' : '4'"
                  :md="getConditionAmountNonLettre ? '2' : '4'"
                >
                  <b-form-group
                    label="Montant à payer"
                    label-for="montant"
                    class="input-modal-champ col-2 p-0 mr-2"
                  >
                    <b-form-input
                      @input="calculRestePayer"
                      v-on:keydown.13="my_method($event)"
                      id="montant"
                      type="number"
                      required
                      :disabled="true"
                      v-model="reglementToUpdate.montant"
                      min="0"
                      step="0.00001"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col col xs="1.25" sm="1.25" md="1.25">
                  <b-form-group label="Unité" label-for="unite" class="mr-2">
                    <b-form-select
                      id="unite"
                      :options="computedUnite"
                      text-field="text"
                      v-model="reglementToUpdate.currency"
                      required
                      value-field="value"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col col xs="2.5" sm="2.5" md="2.5">
                  <b-form-group
                    label="Réference"
                    label-for="ref"
                    class="input-modal-champ col-2 p-0 mr-2"
                  >
                    <b-form-input
                      id="ref"
                      v-on:keydown.13="my_method($event)"
                      type="text"
                      v-model="reglementToUpdate.reference"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Date paiement"
                    label-for="date_paiement"
                    class="input-modal-champ"
                  >
                    <date-picker
                      id="date_paiement"
                      value-type="format"
                      format="DD-MM-YYYY"
                      v-model="reglementToUpdate.payment_date"
                      required
                      type="date"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Reste"
                    label-for="reste"
                    class="input-modal-champ col-2 p-0 mr-2"
                  >
                    <div class="text-dark custom-input-dynamic-reglement">
                      {{ reglementToUpdate.balance | numberWithSpaces }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Solde courant"
                    label-for="solde"
                    class="input-modal-champ col-2 p-0 mr-2"
                  >
                    <div class="text-dark custom-input-dynamic-reglement">
                      {{ reglementToUpdate.solde_init | numberWithSpaces }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col col xs="2" sm="2" md="2">
                  <b-form-group
                    label="Montant Echu"
                    label-for="reste"
                    class="input-modal-champ col-2 p-0 mr-2"
                  >
                    <div class="text-dark custom-input-dynamic-reglement">
                      {{ reglementToUpdate.solde_echeance | numberWithSpaces }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  reglementToUpdate &&
                    reglementToUpdate.canConfirm == true &&
                    reglementToUpdate.type_partenaire == 'Fournisseur'
                "
              >
                <b-col col xs="4" sm="4" md="4"></b-col>
                <b-col col xs="4" sm="1.25" md="1.25">
                  <b-form-group
                    label="Confirmation"
                    label-for="confirmation_fournisseur"
                    class="mr-2"
                  >
                    <b-form-select
                      id="confirmation_fournisseur"
                      :options="computedListConfirm"
                      text-field="text"
                      v-model="reglementToUpdate.confirmation_fournisseur"
                      required
                      value-field="value"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col col xs="4" sm="4" md="4"></b-col>
              </b-row>
              <badge v-if="getConditionAmountNonLettre"/>
              <b-row>
                <b-col col xs="12" sm="12" md="12">
                  <b-form-group
                    label="Note"
                    label-for="note"
                    class="input-modal-champ"
                  ></b-form-group>
                  <b-form-textarea
                    v-on:keydown.13="my_method($event)"
                    id="note"
                    row="3"
                    v-model="reglementToUpdate.payment_note"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-tabs
                content-class="mt-3"
                fill
                class="mt-3 tabs-class-reglement-dynamic"
              >
                <b-tab
                  :title="'Factures régler ' + reglementToUpdate.client"
                  active
                >
                  <div class="height-class-facture-dynamic-reg">
                    <div
                      class="box-all-filter"
                      v-if="
                        computedCheckRows.length != 0 &&
                          ((reglementToUpdate.canConfirm == true &&
                            reglementToUpdate.type_partenaire ==
                              'Fournisseur') ||
                            reglementToUpdate.type_partenaire == 'Client')
                      "
                    >
                      <div class="box-end-position">
                        <b-button
                          size="sm"
                          class="button-export-style"
                          title="Annuler Paiement"
                          @click="$refs['confirmAnnulation'].show()"
                        >
                          <font-awesome-icon
                            icon="wrench"
                            class="file-medical"
                          />
                        </b-button>
                      </div>
                    </div>

                    <div class="body-box-setting body-box-setting-resize">
                      <div
                        class="table-rapport-style fix-rapport-style w-100-p"
                      >
                        <b-table
                          show-empty
                          id="my-table"
                          class="
                            custom-table-style
                            TableFraisUpdateReglementFacture
                            table-header
                          "
                          :items="reglementToUpdate.factures_regles"
                          :fields="fields"
                          bordered
                          sticky-header
                          hover
                          responsive
                          head-variant="light"
                          :tbody-tr-class="rowClass"
                          empty-text="Il n'y a aucun enregistrement à afficher"
                        >
                          <template class="check-th" v-slot:head()="data">
                            <div v-if="data.field.key === 'check_all'">
                              <b-form-checkbox
                                class="check-th"
                                :value="true"
                                :unchecked-value="false"
                                v-model="checkAll"
                                @change="selectAll"
                              >
                              </b-form-checkbox>
                            </div>
                          </template>
                          <template v-slot:cell(check_all)="data">
                            <b-form-checkbox
                              class="check-th"
                              :value="true"
                              :unchecked-value="false"
                              v-model="data.item.check"
                            >
                            </b-form-checkbox>
                          </template>
                          <template v-slot:cell(num)="data"
                            >{{ data.item.num }}
                            <font-awesome-icon
                              v-show="data.item.reglements.length"
                              :id="`popover-1-${data.item.id}`"
                              icon="info"
                              class="
                                info-icon-commercial-lot
                                content-tooltip-style
                                w-25
                              "
                            />

                            <b-popover
                              :target="`popover-1-${data.item.id}`"
                              triggers="hover"
                              custom-class="avoir-tooltip"
                            >
                              <b-table-simple class="table-fiche">
                                <b-tbody>
                                  <b-tr class="title-ligne">
                                    <b-th class="newcolor">Montant reglé</b-th>
                                    <b-th class="newcolor"
                                      >Date de paiement</b-th
                                    >
                                    <b-th class="newcolor"
                                      >Type de paiement</b-th
                                    >
                                  </b-tr>
                                  <template
                                    v-for="(reglement, i) in data.item
                                      .reglements"
                                  >
                                    <b-tr :key="'reglement' + i">
                                      <b-td class="newcolor"
                                        >{{ reglement.amount }} €
                                      </b-td>
                                      <b-td class="newcolor">{{
                                        formateDateGetters(
                                          reglement.payment_date
                                        )
                                      }}</b-td>
                                      <b-td class="newcolor">{{
                                        reglement.payment_type
                                      }}</b-td>
                                    </b-tr>
                                  </template>
                                </b-tbody>
                              </b-table-simple>
                            </b-popover>
                          </template>
                          <template v-slot:cell(total_ttc)="data">{{
                            data.item.total_ttc | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(montant_regle)="data">{{
                            data.item.montant_regle | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(montant_a_regle)="data">
                            {{ data.item.montant_a_regle | numberWithSpaces }}
                          </template>
                          <template v-slot:cell(reste)="data">{{
                            data.item.reste | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(vendeur)="data">{{
                            data.item.vendeur
                          }}</template>
                          <template v-slot:cell(client)="data">{{
                            data.item.acheteur
                          }}</template>
                          <template v-slot:cell(delais_paiement)="data">{{
                            formateDateGetters(data.item.date_echeance)
                          }}</template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab
                  :title="'Factures à régler ' + reglementToUpdate.client"
                  @click="getfactures"
                >
                  <div>
                    <div
                      v-if="show == true"
                      class="loading-custom-template-block"
                    >
                      <Half-circle-spinner
                        :animation-duration="1000"
                        :size="50"
                        :color="'#4d4bac'"
                        class="loeder"
                      />
                    </div>
                    <div class="body-box-setting body-box-setting-resize">
                      <div
                        class="
                          table-rapport-style
                          fix-rapport-style
                          w-100-p
                          d-inline
                        "
                      >
                        <div class="d-flex justify-content-end mb-2">
                          <b-form-group
                            label=""
                            label-for="search-add"
                            class="input-modal-champ mb-0"
                          >
                            <b-form-input
                              autocomplete="off"
                              v-model="search"
                              placeholder="Recherche par numéro de facture"
                              class="b-form-select-raduis"
                            ></b-form-input>
                          </b-form-group>
                        </div>

                        <b-table
                          show-empty
                          id="my-table"
                          class="
                            custom-table-style
                            TableFraisUpdateReglementFacture
                            table-header
                          "
                          :items="facturesReglementComputed"
                          :fields="fields"
                          bordered
                          sticky-header
                          hover
                          responsive
                          head-variant="light"
                          :tbody-tr-class="rowClass"
                          empty-text="Il n'y a aucun enregistrement à afficher"
                        >
                          <template class="check-th" v-slot:head()="data">
                            <div v-if="data.field.key === 'check_all'">
                              <b-form-checkbox
                                class="check-th"
                                :value="true"
                                :unchecked-value="false"
                                @change="handleCheckAll"
                                v-model="checkAll"
                              >
                              </b-form-checkbox>
                            </div>
                          </template>
                          <template v-slot:cell(check_all)="data">
                            <b-form-checkbox
                              v-model="data.item.check"
                              @change="checkFacture(data.item)"
                              class="check-th"
                              :value="true"
                              :unchecked-value="false"
                            >
                            </b-form-checkbox>
                          </template>
                          <template v-slot:cell(num)="data">{{
                            data.item.num
                          }}</template>
                          <template v-slot:cell(total_ttc)="data">{{
                            data.item.total_ttc | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(montant_regle)="data">{{
                            data.item.montant_regle | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(montant_a_regle)="data">
                            <EditableInput
                              :editable="
                                reglementToUpdate.montant != null &&
                                  data.item.check == true &&
                                  reglementToUpdate.balance != 0
                              "
                              champName="montant_a_regle"
                              :item="data.item"
                              :value="data.item.montant_a_regle"
                              type="text"
                              editableType="input"
                              :updateFunction="updateMontantRegler"
                              :defaultColor="true"
                            />
                          </template>
                          <template v-slot:cell(reste)="data">{{
                            data.item.reste | numberWithSpaces
                          }}</template>
                          <template v-slot:cell(vendeur)="data">{{
                            data.item.vendeur
                          }}</template>
                          <template v-slot:cell(client)="data">{{
                            data.item.acheteur
                          }}</template>
                          <template v-slot:cell(delais_paiement)="data">{{
                            formateDateGetters(data.item.date_echeance)
                          }}</template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Fichiers jointes">
                  <div
                    class="
                      body-box-setting body-box-setting-resize
                      height-class-facture-dynamic-reg
                    "
                  >
                    <div class="doc-list-reg-dynamic mt-1 box-upload">
                      <div class="form-type">
                        <div class="form-groupe">
                          <div>
                            <b-form-file
                              ref="file-type"
                              v-model="files"
                              :required="false"
                              placeholder="Aucun fichier selectionné"
                              drop-placeholder="Drop file here..."
                              multiple
                            >
                            </b-form-file>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="files.length">
                    <div class="hader mb-1 mt-1">
                      <div class="titleSetting">Nouveau(x) Fichier(s)</div>
                    </div>
                    <div
                      class="
                        body-box-setting body-box-setting-resize
                        height-class-facture-dynamic-reg
                      "
                    >
                      <div class="doc-list-reg-dynamic mt-1 box-upload">
                        <div class="form-type">
                          <div class="form-groupe">
                            <div
                              id="upload-file-component"
                              class="mt-3"
                              v-if="files"
                            >
                              <div
                                class="files-to-upload-reg-dynamic"
                                v-for="(file, index) in files"
                                :key="'file' + index"
                              >
                                <p class="file-name">
                                  <font-awesome-icon
                                    icon="paperclip"
                                    class="file-upload-icon ml-3 mr-2"
                                  />
                                  <a :href="file.link" target="_blank">{{
                                    file.name
                                  }}</a>
                                </p>
                                <p class="file-name">
                                  <b-form-group
                                    label="Description"
                                    label-for="description"
                                    class="input-modal-champ"
                                  ></b-form-group>
                                  <b-form-textarea
                                    id="description"
                                    v-model="file.description"
                                  ></b-form-textarea>
                                </p>
                                <p class="file-name-icon">
                                  <font-awesome-icon
                                    icon="trash"
                                    class="file-trash-upload-icon mr-5"
                                    style="float: right"
                                    @click.prevent.stop="
                                      deleteFileUpload(file, index)
                                    "
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      reglementToUpdate &&
                        reglementToUpdate.files &&
                        reglementToUpdate.files.length
                    "
                  >
                    <div class="hader mb-1 mt-1">
                      <div class="titleSetting">Fichier(s) Récent(s)</div>
                    </div>
                    <div
                      class="
                        body-box-setting body-box-setting-resize
                        height-class-facture-dynamic-reg
                      "
                    >
                      <div class="doc-list-reg-dynamic mt-1 box-upload">
                        <div class="form-type">
                          <div class="form-groupe">
                            <div id="upload-file-component" class="mt-3">
                              <div
                                class="files-to-upload-reg-dynamic"
                                v-for="(file, index) in reglementToUpdate.files"
                                :key="'file' + index"
                              >
                                <p class="file-name">
                                  <font-awesome-icon
                                    icon="paperclip"
                                    class="file-upload-icon ml-3 mr-2"
                                  />
                                  <a :href="file.link" target="_blank">{{
                                    file.name
                                  }}</a>
                                </p>
                                <p class="file-name">
                                  <b-form-group
                                    label="Description"
                                    label-for="description"
                                    class="input-modal-champ"
                                  ></b-form-group>
                                  <b-form-textarea
                                    id="description"
                                    v-model="file.description"
                                  ></b-form-textarea>
                                </p>
                                <p class="file-name-icon">
                                  <font-awesome-icon
                                    icon="trash"
                                    class="file-trash-upload-icon mr-5"
                                    style="float: right"
                                    @click.prevent.stop="
                                      deleteFile(file, index)
                                    "
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab
                  :title="'Échéancier de paiement '"
                  v-if="
                    computedPaiementNameSelected.includes('LCR') &&
                      reglementToUpdate.echeances
                  "
                >
                  <div
                    class="box-all-filter mb-2"
                    v-if="computedCheckRowsEcheans.length != 0"
                  >
                    <div class="box-end-position">
                      <b-button
                        size="sm"
                        class="button-export-style"
                        title="Téléchargement PDF d'écheances"
                        @click.prevent.stop="exportMultipleEcheance"
                      >
                        <font-awesome-icon
                          icon="arrow-alt-circle-down"
                          class="file-medical"
                        />
                      </b-button>
                    </div>
                  </div>
                  <b-table-simple
                    class="
                      table-fiche
                      m-0
                      p-0
                      d-flex
                      justify-content-center
                      block-details-injection
                      tableInjection
                    "
                    responsive
                  >
                    <b-tbody>
                      <b-tr class="title-ligne">
                        <b-th class="newcolor"
                          ><b-form-checkbox
                            v-model="checkAllEcheance"
                            name="flavour-1"
                            class="check-th"
                            :value="true"
                            @change="functionCheckAllEcheance"
                            :unchecked-value="false"
                          >
                          </b-form-checkbox
                        ></b-th>
                        <b-th class="newcolor">Libellé</b-th>
                        <b-th class="newcolor">Séquence</b-th>
                        <b-th class="newcolor">Date de création</b-th>
                        <b-th class="newcolor">Date d'échéance</b-th>
                        <b-th class="newcolor">Client/Fournisseur</b-th>
                        <b-th class="newcolor">Devise</b-th>
                        <b-th class="newcolor">Banque du tiré</b-th>
                        <b-th class="newcolor">Montant</b-th>
                        <b-th class="newcolor">Date d'envoi</b-th>
                        <b-th class="newcolor">Date de signature</b-th>
                        <b-th class="newcolor">Date valeur</b-th>
                      </b-tr>
                      <template
                        v-for="(item, index) in reglementToUpdate.echeances"
                      >
                        <b-tr :key="'eche' + index">
                          <b-td class="newcolor"
                            ><b-form-checkbox
                              v-model="item.check"
                              name="flavour-1"
                              class="check-th"
                              :value="true"
                              @change="checkRowsEcheances(item)"
                              :unchecked-value="false"
                            >
                            </b-form-checkbox
                          ></b-td>
                          <b-td class="newcolor">{{ item.libelle }}</b-td>
                          <b-td class="newcolor"
                            >{{ item.sequence }}
                            <font-awesome-icon
                              icon="arrow-alt-circle-down"
                              @click.prevent="downloadFacture(item.id)"
                              class="icon-style-color mr-1"
                              title="Télécharger la facture"
                            />
                          </b-td>
                          <b-td class="newcolor">{{ item.date_creation }}</b-td>
                          <b-td class="newcolor">
                            <date-picker
                              v-model="item.date_echeance"
                              value-type="format"
                              type="date"
                              required
                              input-class="custom-date-picker-filter"
                              class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                            ></date-picker
                          ></b-td>
                          <b-td class="newcolor">{{
                            reglementToUpdate.client
                          }}</b-td>
                          <b-td class="newcolor">{{ item.devise }}</b-td>
                          <b-td class="newcolor">{{ item.rib.rib_bank }}</b-td>
                          <b-td class="newcolor">
                            <b-form-input
                              min="0"
                              step="0.00001"
                              :max="reglementToUpdate.montant"
                              type="number"
                              autocomplete="off"
                              v-model="item.amount"
                              @input="changeMontantEcheance(index)"
                            ></b-form-input
                          ></b-td>
                          <b-td class="newcolor"
                            ><date-picker
                              v-model="item.date_envoi"
                              value-type="format"
                              type="date"
                              required
                              input-class="custom-date-picker-filter"
                              class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                            ></date-picker
                          ></b-td>
                          <b-td class="newcolor">
                            <date-picker
                              v-model="item.date_signature"
                              value-type="format"
                              type="date"
                              required
                              input-class="custom-date-picker-filter"
                              class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                            ></date-picker
                          ></b-td>
                          <b-td class="newcolor">
                            <date-picker
                              v-model="item.date_valeur"
                              value-type="format"
                              type="date"
                              required
                              input-class="custom-date-picker-filter"
                              class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                            ></date-picker
                          ></b-td>
                        </b-tr>
                      </template>
                      <b-tr v-if="reglementToUpdate.echeances.length > 0">
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor"
                          >{{ reglementToUpdate.totalEcheance }}
                        </b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                        <b-td class="newcolor">-</b-td>
                      </b-tr>
                      <b-tr v-if="reglementToUpdate.echeances.length == 0">
                        <b-td colspan="12">
                          Il n'y a aucune tranche à afficher</b-td
                        >
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-tab>
              </b-tabs>
              <div class="actionModel mt-3">
                <div class="error-message ml-2 mr-2">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)" class="mb-0">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style mt-3">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
                <b-button
                  v-if="
                    (reglementToUpdate.canConfirm == true &&
                      reglementToUpdate.type_partenaire == 'Fournisseur') ||
                      reglementToUpdate.type_partenaire == 'Client'
                  "
                  type="reset"
                  class="button-cancel-style mt-3 ml-3"
                  @click.prevent.stop="handleModalAnnulerTotal"
                >
                  <span>
                    Annuler le Paiement
                    <div v-if="loadingAnnuler" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button></div
            ></template>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="AnnulationTotal"
      ref="AnnulationTotal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Annulation Totale de Paiement</div>
        <div class="iconClose" @click.prevent="hideModal('AnnulationTotal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="AnnulerReglementTotal"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Vous devez confirmer l'annulation totale de ce Paiement?
              </p>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div class="loading ml-2" v-if="loadingValideAnnuler">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent.stop="annulerConfirmationTotal"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="confirmAnnulation"
      ref="confirmAnnulation"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Annuler le(s) Paiement(s) sélectionnés</div>
        <div class="iconClose" @click.prevent="hideModal('confirmAnnulation')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitAnnulationReglement"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Vous devez confirmer l'annulation de ce(s) Paiement(s) ?
              </p>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div class="loading ml-2" v-if="annulerReglement">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent.stop="annulerConfirmation"
              >
                <span>
                  Annuler
                  <div class="loading ml-2" v-if="loadingAnnuler">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="ModalPieceJoint"
      id="ModalPieceJoint"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      title="Facture"
      modal-class="cutsom-modal-bootstrap  custom-modal-upload-files"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Fichiers jointes</div>
        <div class="iconClose" @click.prevent="hideModal('ModalPieceJoint')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitFiles"
          >
            <div class="body-box-files">
              <div class="doc-list-file mt-1 box-upload">
                <div class="form-type">
                  <div class="form-groupe">
                    <div>
                      <b-form-file
                        ref="file-type"
                        v-model="files"
                        :required="false"
                        placeholder="Aucun fichier selectionné"
                        drop-placeholder="Drop file here..."
                        multiple
                      >
                      </b-form-file>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="files.length != 0">
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Nouveau(x) Fichier(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            {{ file.name }}
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="deleteFileUpload(index)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                reglementToUpdate &&
                  reglementToUpdate.files &&
                  reglementToUpdate.files.length != 0
              "
            >
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Fichier(s) Récent(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in reglementToUpdate.files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            <a :href="file.link" target="_blank">{{
                              file.name
                            }}</a>
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="deleteFile(file, index)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mr-2">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style"
                @click.prevent.stop="hideModal('ModalPieceJoint')"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import Reglements from '@/models/Reglements';

import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    item: { required: true },
    dataInterface: { required: true },
    grouped: { default: true }
  },
  data() {
    return {
      itemReglement: null,
      reglementToUpdate: null,
      show: false,
      showEcheance: false,
      reglement: null,
      search: null,
      files: [],
      error: [],
      loading: false,
      loadingAnnuler: false,
      checkAll: false,
      resetModalLcr: false,
      dataLcr: null,
      checkAllEcheance: false,
      loadingValideAnnuler: false,
      annulerReglement: false,
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'width-service',
          tdClass: 'width-service'
        },
        {
          key: 'num',
          label: 'Numéro'
        },
        {
          key: 'total_ttc',
          label: 'Montant Total'
        },
        {
          key: 'reste',
          label: 'Reste'
        },
        {
          key: 'montant_regle',
          label: 'Montant Réglé'
        },
        {
          key: 'montant_a_regle',
          label: 'Montant à Régler'
        },
        {
          key: 'vendeur',
          label: 'Société'
        },
        {
          key: 'client',
          label: 'Client'
        },
        {
          key: 'delais_paiement',
          label: 'Délais du paiement'
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'soldeClient',
      'soldeEcheance',
      'TypeReglement',
      'facturesReglement',
      'ConditionPaiement',
      'formateDateGetters',
      'getListProjectsFraisDync',
      'getAmountNonLettrerReglemnt',
      'factureScis',
      'factureMensuels',
      'factureMaster2',
      'getFacturesLibresTh',
      'getConditionAmountNonLettre'
      //   'clientsReglement'
    ]),
    computedListConfirm() {
      let confirmList = [
        {
          text: 'Oui',
          value: true
        },
        {
          text: 'Non',
          value: false
        }
      ];
      return confirmList;
    },
    computedCheckRowsEcheans() {
      let tableCheck = [];
      this.reglementToUpdate.echeances?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedPaiementNameSelected() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedCheckRows() {
      let tableCheck = [];
      this.reglementToUpdate.factures_regles?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    facturesReglementComputed() {
      if (this.search != null && this.search != '') {
        return this.facturesReglement.filter(item =>
          item.num.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.facturesReglement;
    }
  },
  methods: {
    ...mapActions([
      'getSoldeClient',
      'getSoldeEcheance',
      'fetachDataWithReglementId',
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'getFactureByFilialeReglement',
      'updateReglement',
      'annulationPayment',
      'annulationPaymentFacture',
      'updateFileReglement',
      'AttachFiles',
      'updatePaymentEcheance',
      'exportEcheanceReglement',
      'getAmountNonLettrer'
    ]),
    updateDataInHomeTable(responseUpdate) {
      let dataToUseInUpdate =
        this.dataInterface == 'frais'
          ? this.getListProjectsFraisDync
          : this.dataInterface == 'sci'
          ? this.factureScis
          : this.dataInterface == 'mensual'
          ? this.factureMensuels
          : this.dataInterface == 'master'
          ? this.factureMaster2
          : this.dataInterface == 'libre'
          ? this.getFacturesLibresTh
          : [];

      let FolderName = this.dataInterface == 'frais' ? 'projects' : 'factures';
      responseUpdate.forEach(reg => {
        let indexFacture = -1;
        if (this.grouped) {
          let indexFiliale = dataToUseInUpdate.findIndex(i =>
            i[FolderName].some(e => e.id == reg.id)
          );
          if (indexFiliale != -1) {
            indexFacture = dataToUseInUpdate[indexFiliale][
              FolderName
            ].findIndex(i => i.id == reg.id);
            dataToUseInUpdate[indexFiliale][FolderName][
              indexFacture
            ].updateDataReglement(reg);
          }
        } else {
          indexFacture = dataToUseInUpdate.findIndex(i => i.id == reg.id);
          if (indexFacture != -1) {
            dataToUseInUpdate[indexFacture].updateDataReglement(reg);
          }
        }
      });
    },
    annulerConfirmationTotal() {
      this.$refs['AnnulationTotal'].hide();
    },
    annulerConfirmation() {
      this.hideModal('confirmAnnulation');
      this.reglementToUpdate.factures_regles.map(reglement => {
        if (reglement.check == true) {
          reglement.check = false;
        }
      });
    },
    async exportMultipleEcheance() {
      let bodyFormData = new FormData();
      let checked = [];
      checked = this.reglementToUpdate.echeances.filter(
        item => item.check == true
      );
      checked.forEach((element, index) => {
        bodyFormData.append('ids[' + index + ']', element.id);
      });
      const response = await this.exportEcheanceReglement(bodyFormData);
      if (response) {
        this.reglementToUpdate.echeances.forEach((element, index) => {
          element.check = false;
        });
        this.checkAllEcheance = false;
      }
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.reglementToUpdate.echeances.length - 1
          ? 0
          : this.reglementToUpdate.echeances.length - 1;

      let index_start =
        index == this.reglementToUpdate.echeances.length - 1 ? 1 : 0;
      let index_end =
        index == this.reglementToUpdate.echeances.length - 1
          ? this.reglementToUpdate.echeances.length
          : this.reglementToUpdate.echeances.length - 1;
      this.reglementToUpdate.echeances.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.amount);
        }
      });
      this.reglementToUpdate.echeances[index_update].amount = (
        this.reglementToUpdate.totalEcheance - sum
      ).toFixed(2);
    },
    async downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      await this.exportEcheanceReglement(bodyFormData);
    },
    checkRowsEcheances(item) {
      let checked = [];
      if (item.check == false && this.checkAllEcheance == true) {
        this.checkAllEcheance = false;
      }
      this.reglementToUpdate?.echeances?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
      });
      if (checked.length == this.reglementToUpdate.echeances.length) {
        this.checkAllEcheance = true;
      }
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.reglementToUpdate.files.length != 0) {
        this.loading = true;
        if (this.reglementToUpdate?.files?.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.reglementToUpdate.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.reglementToUpdate.files[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.reglementToUpdate.files[i].description
            );
          }

          const response = await this.updateFileReglement({
            bodyFormData: bodyFormData,
            reglement: this.reglementToUpdate
          });
          if (response.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('payment_id', this.reglementToUpdate.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const response = await this.AttachFiles({
                bodyFormData: bodyFormData,
                payment_id: this.reglementToUpdate.id
              });
              if (response.success) {
                this.loading = false;
                this.files = [];
                this.hideModal('ModalPieceJoint');
                return true;
              }
            } else {
              this.files = [];
              this.loading = false;
              this.hideModal('ModalPieceJoint');
              return true;
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('payment_id', this.reglementToUpdate.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const response = await this.AttachFiles({
              bodyFormData: bodyFormData,
              payment_id: this.reglementToUpdate.id
            });

            if (response.success) {
              this.loading = false;
              this.hideModal('ModalPieceJoint');
              return true;
            }
          }
        }
      }
    },
    async submitAnnulationReglement() {
      this.annulerReglement = true;
      let bodyFormData = new FormData();
      bodyFormData.append(
        'source',
        this.dataInterface == 'frais' ? 'support' : 'libre'
      );
      let indexAnnulationReglement = 0;
      for (let j = 0; j < this.reglementToUpdate.factures_regles.length; j++) {
        if (this.reglementToUpdate.factures_regles[j].check == true) {
          for (
            let i = 0;
            i < this.reglementToUpdate.factures_regles[j].reglements.length;
            i++
          ) {
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].facture_id
            );
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][payment_facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].id
            );
            indexAnnulationReglement = indexAnnulationReglement + 1;
          }
        }
      }
      const response = await this.annulationPaymentFacture({
        bodyFormData: bodyFormData,
        oldReglement: this.reglementToUpdate,
        source: this.dataInterface == 'frais' ? 'support' : 'libre'
      });
      if (response.succes) {
        //mettre a jour done
        this.updateDataInHomeTable(response.data);
        this.reglementToUpdate.factures_regles = this.reglementToUpdate.factures_regles.filter(
          i => i.check == false
        );
        this.annulerReglement = false;
        this.hideModal('confirmAnnulation');
      }
    },
    async AnnulerReglementTotal() {
      this.loadingValideAnnuler = true;
      const response = await this.annulationPayment({
        id: this.reglementToUpdate.id,
        oldReglement: this.reglementToUpdate,
        source: this.dataInterface == 'frais' ? 'support' : 'libre'
      });
      if (response) {
        this.updateDataInHomeTable(response.data);
        this.reglementToUpdate.factures_regles = [];
        this.loadingValideAnnuler = false;
        this.$refs['AnnulationTotal'].hide();
        this.hideModal('updateReglementFacture');
      }
    },
    handleModalAnnulerTotal() {
      this.$refs['AnnulationTotal'].show();
    },
    functionCheckAllEcheance() {
      this.reglementToUpdate.echeances.map(item => {
        item.check = this.checkAllEcheance;
      });
    },
    async handleSubmitUpdateReglement() {
      if (this.reglementToUpdate.montant == 0) {
        this.error = 'Il faut avoir un montant à payer supèrieur à 0 ';
      } else {
        this.loading = true;
        this.reglementToUpdate.echeances.forEach(async (element, index) => {
          let bodyFormDataEcheance = new FormData();
          bodyFormDataEcheance.append('id', element.id);
          bodyFormDataEcheance.append('libelle', element.libelle);
          bodyFormDataEcheance.append('client_id', element.client_id);
          bodyFormDataEcheance.append('date_creation', element.date_creation);
          bodyFormDataEcheance.append('date_echeance', element.date_echeance);
          bodyFormDataEcheance.append(
            'beneficiaire_id',
            element.beneficiaire_id
          );
          bodyFormDataEcheance.append('valeur_en', element.valeur_en);
          bodyFormDataEcheance.append('date_envoi', element.date_envoi);
          bodyFormDataEcheance.append('date_signature', element.date_signature);
          bodyFormDataEcheance.append('date_valeur', element.date_valeur);
          bodyFormDataEcheance.append('devise', element.devise);
          bodyFormDataEcheance.append('amount', element.amount);
          bodyFormDataEcheance.append('rib_id', element.rib.id);
          bodyFormDataEcheance.append('sequence', element.sequence);
          bodyFormDataEcheance.append('etat', element.etat);
          bodyFormDataEcheance.append('ordre_de', element.ordre_de);
          const response = await this.updatePaymentEcheance(
            bodyFormDataEcheance
          );
          if (response.succes) {
            this.reglementToUpdate.echeances[index].amount =
              response.data.amount;
            this.reglementToUpdate.echeances[index].date_creation =
              response.data.date_creation;
            this.reglementToUpdate.echeances[index].date_echeance =
              response.data.date_echeance;
            this.reglementToUpdate.echeances[index].date_signature =
              response.data.date_signature;
            this.reglementToUpdate.echeances[index].date_valeur =
              response.data.date_valeur;
            this.reglementToUpdate.echeances[index].date_envoi =
              response.data.date_envoi;
          }
        });
        let bodyFormData = new FormData();
        bodyFormData.append('payment_id', this.reglementToUpdate.id);
        bodyFormData.append('balance', this.reglementToUpdate.balance);
        bodyFormData.append('amount', this.reglementToUpdate.montant);
        bodyFormData.append('currency', this.reglementToUpdate.currency);
        bodyFormData.append('canConfirm', this.reglementToUpdate.canConfirm);
        bodyFormData.append(
          'confirmation_fournisseur',
          this.reglementToUpdate.confirmation_fournisseur
        );
        bodyFormData.append(
          'payment_date',
          this.reglementToUpdate.payment_date
        );
        bodyFormData.append('type_id', this.reglementToUpdate.type_id);
        if (!this.computedPaiementNameSelected.includes('LCR')) {
          bodyFormData.append(
            'payment_condition_id',
            this.reglementToUpdate.condition_id
          );
        }
        bodyFormData.append('filiale_id', this.reglementToUpdate.client_id);
        bodyFormData.append(
          'payment_note',
          this.reglementToUpdate.payment_note
        );
        bodyFormData.append('reference', this.reglementToUpdate.reference);
        bodyFormData.append(
          'type_partenaire',
          this.reglementToUpdate.type_partenaire
        );
        let reg = this.facturesReglement.filter(i => i.check == true);
        for (let i = 0; i < reg.length; i++) {
          bodyFormData.append(
            'factures[' + i + '][id]',
            this.facturesReglement[i].id
          );
          bodyFormData.append(
            'factures[' + i + '][montant_regle]',
            this.facturesReglement[i].montant_a_regle
          );
          bodyFormData.append(
            'factures[' + i + '][total_ttc]',
            this.facturesReglement[i].total_ttc
          );
        }
        const responseUpdate = await this.updateReglement({
          bodyFormData: bodyFormData,
          oldReglement: this.reglementToUpdate
        });

        if (responseUpdate.succes) {
          this.updateDataInHomeTable(responseUpdate.data.factures_regles);
          if (
            this.dataLcr &&
            this.dataLcr?.lcr &&
            this.dataLcr?.lcr?.length != 0 &&
            this.reglementToUpdate.type_id != null &&
            this.computedPaiementNameSelectedLcr.includes('LCR') &&
            this.reglementToUpdate.echeances.length == 0
          ) {
            let bodyFormDataEch = new FormData();
            this.dataLcr?.lcr?.forEach((element, i) => {
              bodyFormDataEch.append(
                'echeances[' + i + '][libelle]',
                element.libelle
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][client_id]',
                this.dataLcr.client_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][date_echeance]',
                element.date
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][beneficiaire_id]',
                this.dataLcr.beneficiaire_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][valeur_en]',
                this.dataLcr.valeur_en
              );
              bodyFormDataEch.append('echeances[' + i + '][date_envoi]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][date_signature]',
                null
              );
              bodyFormDataEch.append('echeances[' + i + '][date_valeur]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][devise]',
                this.dataLcr.devise
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][amount]',
                element.value
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][rib_id]',
                this.dataLcr.rib.id
              );
            });
            const resEch = await this.createReglementLcrFactureEcheances({
              response: this.reglementToUpdate,
              data: bodyFormDataEch
            });
            if (resEch) {
              let total = 0;
              this.reglementToUpdate.echeances.map(item => {
                total = total + item.amount;
              });
              this.reglementToUpdate.totalEcheance = total.toFixed(2);
            }
          }
          const responseFile = await this.handleSubmitFiles();
          if (responseFile) {
            this.files = [];
          }
          this.loading = false;
          this.show = true;
          this.getAmountNonLettrer({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          const response = await this.getFactureByFilialeReglement({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });

          if (response) {
            // this.clientsReglement.map(item => {
            //   if (item.id == this.reglementToUpdate.client_id) {
            //     this.vendeur = item.name;
            //   }
            // });
            const response = await this.getSoldeClient({
              id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            if (response) {
              this.reglementToUpdate.solde = this.soldeClient;
              this.reglementToUpdate.solde_init = this.soldeClient;
              if (response) {
                this.reglementToUpdate.solde_echeance = this.soldeEcheance;
                this.show = false;
              }
            }
          }

          // this.hideModal('updateReglementFacture');
        } else {
          this.loading = false;
        }
      }
    },
    checkFacture(item) {
      let dataCheck = this.facturesReglement.filter(
        i => i.check == true && i.id != item.id
      );
      if (
        dataCheck.length == 0 ||
        (dataCheck.length > 0 && dataCheck[0].vendeur_id == item.vendeur_id)
      ) {
        this.reglementToUpdate.balance = parseFloat(
          this.reglementToUpdate.balance
        );
        this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);
        if (this.reglementToUpdate.balance > 0) {
          if (item.check == true) {
            if (this.reglementToUpdate.balance > item.resteInitial) {
              this.reglementToUpdate.balance =
                this.reglementToUpdate.balance - parseFloat(item.resteInitial);
              this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
                2
              );
              item.reste = 0;
              item.montant_a_regle =
                parseFloat(item.montant_a_regle) +
                parseFloat(item.resteInitial);
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            } else {
              item.montant_a_regle = this.reglementToUpdate.balance.toFixed(2);
              item.reste =
                parseFloat(item.resteInitial) - this.reglementToUpdate.balance;
              item.reste = item.reste.toFixed(2);
              this.reglementToUpdate.balance = 0;
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            }
          } else {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            if (this.computedCheckAll == true) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
          }
        } else {
          if (item.check == false) {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            this.checkAll = false;
          } else {
            item.check = false;
            this.checkAll = false;
          }
        }
      } else {
        item.check = !item.check;
      }
    },
    saveDataForLcr(data) {
      this.dataLcr = data;
    },
    updateMontantRegler(payload) {
      this.reglementToUpdate.balance = parseFloat(
        this.reglementToUpdate.balance
      );
      this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);

      if (
        parseFloat(payload.row.montant_a_regle) +
          parseFloat(this.reglementToUpdate.balance) >
        parseFloat(payload.value)
      ) {
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - payload.value.toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance - parseFloat(payload.value);
        this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
          2
        );
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde - parseFloat(payload.value);
        this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(2);
      }
    },
    computedReg() {
      return {
        filiale_id: this.reglementToUpdate.client_id,
        type_reglement: this.reglementToUpdate.type_id,
        unite: this.reglementToUpdate.currency,
        montant_a_payer: this.reglementToUpdate.montant
      };
    },
    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglementComputed.filter(
          item => item.check == false
        );
        table.map(item => {
          if (this.reglementToUpdate.balance > 0) {
            item.check = true;
            this.checkFacture(item);
          }
        });
      } else {
        let table = this.facturesReglementComputed.filter(
          item => item.check == true
        );
        table.map(item => {
          if (item.check == true) {
            item.check = false;
            this.checkFacture(item);
          }
        });
      }
    },
    selectAll() {
      if (this.reglementToUpdate.balance != 0) {
        if (this.checkAll == true) {
          this.facturesReglement.map(item => {
            if (this.reglementToUpdate.balance > 0) {
              item.check = true;
              this.checkFacture(item);
            }
          });
        } else {
          this.facturesReglement.map(item => {
            if (item.check == true) {
              item.check = false;
              this.checkFacture(item);
            }
          });
        }
      } else {
        this.checkAll = false;
      }
    },
    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    async handleUpdate() {
      this.$emit('openModal');
      this.$refs['updateReglementFactureDynamic'].show();
      this.show = true;
      const res = await this.fetachDataWithReglementId(this.item);
      if (res.success) {
        this.itemReglement = Reglements.create(res.data);
        let total = 0;
        this.reglementToUpdate = this.itemReglement;
        this.reglementToUpdate.echeances.map(item => {
          total = total + item.amount;
        });
        if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
          this.computedTypeReglement.map(item => {
            if (
              item.value == this.reglementToUpdate.type_id &&
              item.text == 'LCR Lettre de change Relevé'
            ) {
              this.showEcheance = true;
            }
          });
        }
        this.reglementToUpdate.totalEcheance = total.toFixed(2);
        this.reglement = {
          filiale_id: this.itemReglement.client_id,
          type_reglement: this.itemReglement.type_id,
          unite: this.itemReglement.currency,
          montant_a_payer: this.itemReglement.montant
        };
        this.getAmountNonLettrer({
          filiale_id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        const response = await this.getSoldeClient({
          id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        if (response) {
          this.reglementToUpdate.solde = this.soldeClient;
          this.reglementToUpdate.solde_init = this.soldeClient;
          const response = await this.getSoldeEcheance({
            id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (response) {
            this.reglementToUpdate.solde_echeance = this.soldeEcheance;
          }
        }
      }

      this.show = false;
    },
    calculRestePayer() {
      if (
        parseFloat(this.reglementToUpdate.montant) >
        this.reglementToUpdate.montant_initial
      ) {
        let montant =
          parseFloat(this.reglementToUpdate.montant) -
          this.reglementToUpdate.montant_initial;
        this.reglementToUpdate.balance =
          parseFloat(this.reglementToUpdate.balance) + montant;
        let TableF = this.facturesReglement?.filter(
          item => item.check == false
        );
        if (TableF.length != this.facturesReglement.length) {
          this.getFacturesFiliales();
        }
      }
    },
    hideModal(ref) {
      this.$refs[ref]?.hide();
      if (ref == 'updateReglementFactureDynamic') {
        this.$emit('closeModal');
      }
      this.resetModal();
    },
    resetModal() {
      this.show = false;
    },
    async getfactures() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.reglementToUpdate.balance = this.reglementToUpdate.balance_initial;
        this.show = false;
      }
    }
  },
  filters: {
    numberWithSpaces: x => {
      // return new Intl.NumberFormat().format(x);
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue'),
    lcr: () => import('@/views/component/reglement/lcr.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    badge: () => import('@/views/component/badge.vue')
  },
  mounted() {
    this.getAllTypesReglement();
    this.getAllConditionsPaiement();
  }
};
</script>

<style lang="scss">
.cutsom-modal-bootstrap-reglement-dynamic .sous-card {
  min-height: 67vh;
}
.cutsom-modal-bootstrap-reglement-dynamic .modal-dialog {
  top: 0% !important;
  height: calc(100vh - 32px) !important;
  max-width: 90% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-reglement-dynamic .modal-dialog {
    top: 0%;
    max-width: 90%;
    height: 100% !important;
    margin: auto;
  }
}
div:has(#updateReglementFactureDynamic) {
  z-index: 1041 !important;
}
.doc-list-reg-dynamic {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
.height-class-facture-dynamic-reg {
  .body-box-rapport {
    height: calc(100vh - 264px) !important;
  }
}
.tabs-class-reglement-dynamic {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
.body-box-setting-resize {
  height: auto;
}
.update-reglement-dynamic {
  .file-medical {
    font-size: 13px;
  }
  .color-text {
    color: black;
  }
  .list-type {
    list-style-type: none;
  }
  .TableFraisUpdateReglementFacture {
    max-height: calc(100vh - 545px);
    height: calc(100vh - 545px);
  }
}
.custom-input-dynamic-reglement {
  width: 100% !important;
  height: 31px;
  border-radius: 17px;
  padding-top: 5px;
  background-color: #7070701c;
}
.files-to-upload-reg-dynamic {
  display: flex;
  align-items: center;
  padding: 2px 20px;

  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
    margin-right: 10px;
    text-align: left;
    font-size: 1rem;
    color: black;

    .file-upload-icon {
      color: #2dabe2;
    }
    .file-trash-upload-icon {
      color: red;
      cursor: pointer;
    }
  }
  .file-name-trash {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20%;
    margin-right: 10px;
    text-align: left;
    font-size: 1rem;
    color: black;

    .file-upload-icon {
      color: #2dabe2;
    }
    .file-trash-upload-icon {
      color: red;
      cursor: pointer;
    }
  }

  .file-category {
    width: 40%;
    font-size: 13px;
    border-radius: 17px;
  }
}
</style>
